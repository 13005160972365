<template>
  <v-layout column class="ml-4">
    <DefaultLabel v-if="label" class="mb-1">{{ label }}</DefaultLabel>
    <div v-if="html" class="text-body-1 mt-1" v-html="value"></div>
    <div v-else class="text-body-1 preview mt-1" :style="{ color: color }">
      {{ value }}
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    label: {},
    color: {
      default: "black",
    },
    html: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
  },
};
</script>

<style lang="css" scoped>
.preview {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
</style>